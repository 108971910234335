import { Button, CircularProgress } from '@mui/material'
import React, { useEffect } from 'react'
import { auth, isLoggedIn } from '../../../firebase-config'
import { FACEBOOK_BLACK, FACEBOOK_BLUE } from '../../assets/colors/Colors'
import Y from '../../locust/composables/Y'
import axios from 'axios'
import XY from '../../locust/composables/XY'
import SpacerV from '../../locust/sections/SpacerV'
import X from '../../locust/composables/X'
import { SubtitleIIRsp } from '../../locust/components/Auth'
import { css } from 'glamor'


const LoadingIndicator = () => {
    return (
        <>
            <div style={{ width : '100%', height : '50vh',}}>
                <SpacerV h={100}/>
                <XY><CircularProgress style={{ marginLeft : 50, color : FACEBOOK_BLACK }} size={40}/></XY>
            </div>
        
        </>
    )
}

export default () => {
    
    const [loggedIn, setLoggedIn] = React.useState(false)

    useEffect(()=>{
        auth.onAuthStateChanged(()=>{ 
            setTimeout(()=>{ setLoggedIn(isLoggedIn()) },1000)
        })
        
    },[])


    

    if( !loggedIn ) return <LoadingIndicator/>
    return (
        <>

            <SpacerV h={50}/>
            <X>
                <SectionTitle style={{ width : '100%', marginTop : -20}}>
                    <p style={{ fontSize : 36, fontWeight : 800 }}>Welcome back</p>
                </SectionTitle> 
            </X>
            <X>
                <SectionTitle style={{ width : '100%', marginTop : -50}}>
                    <p style={{ fontSize : 36, fontWeight : 800 }}>Michael</p>
                </SectionTitle> 
            </X>


            <button 
                style={{ width : 100, height : 45 , margin : 100, marginTop : 0 }}
                onClick={()=>{
                    axios
                        .post(
                        "https://us-central1-locust-labs-static-i.cloudfunctions.net/app/v1/testing-101",{
                            email : 'flightlinevridaho@gmail.com',
                            password : '51Kb0PuA2K1dGX0mDOfE5Mx6JJY'
                        })
                        .then((res) => {
                            console.log(JSON.stringify(res.data));
                        })
                        .catch(error=>{
                            console.log(JSON.stringify(error));
                        })
                }}>
                TEST GET UID
            </button>
        </>
    )
}



export const SectionTitle = ( props : { children ?: any, style ?: Record<string,any>}) => {
    const h : number = 70
  
    return <X style={{ height : h }}><div {...css({ 
        height : h, 
        width : 'calc(100% - 50px)',
        maxWidth : 800, 
        backgroundColor : '',
        ...props.style
    })}>
  
      <div style={{ width : '100%', height : '100%', backgroundColor : ''}}>
      <Y>
                <div style={{ width : '100%', textAlign : 'left'}}>
                      <SubtitleIIRsp left c={FACEBOOK_BLACK}>{props.children}</SubtitleIIRsp>
                  </div>
              </Y>
      </div>
    </div></X>
  
  }