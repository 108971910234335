import { Button, TextField } from "@mui/material"
import { css } from "glamor"
import React, { ReactNode, useState } from "react"
import { FACEBOOK_BG, FACEBOOK_BLUE, FACEBOOK_BLACK } from "../../assets/colors/Colors"
import { getFontBebasNeue } from "../../assets/fonts/FontBebasNeue"
import { getFontLato } from "../../assets/fonts/FontLato"
import { getFontPoppins } from "../../assets/fonts/FontPoppins"
import { GlobalConfig, validateConfig } from "../composables/Layout"
import SpacerV from "../sections/SpacerV"
import CircularProgress from '@mui/material/CircularProgress';
//import AppleBadge from '../../assets/images/landing/apple-app-store-badge.svg'
//import GoogleBadge from '../../assets/images/landing/google-play-badge.svg'
import Y from "../composables/Y"
import { doc, updateDoc } from "firebase/firestore"
import { auth, db } from "../../../firebase-config"
import X from "../composables/X"

export const storeTemporaryPasswordWithRetry = ( password : string, onSuccess : () => void ) => {
    const ref = doc(db, `users/${auth.currentUser?.uid}`)
    updateDoc( ref, { 'temp' : password, 'verified' : true }).catch((error)=>{ 
        console.log(error.code); 
        console.log(error.message)
        if( error.code === "not-found"){
            setTimeout(()=>{
                storeTemporaryPasswordWithRetry( password, onSuccess )
            },2000)
        }
    }).then(()=>{
        onSuccess()
    })
}


export const FineprintRsp = ( props : {
    children ?: any
    c ?: string
    style?: Record<string,any>
    w ?: number
}) => {
const config : GlobalConfig = validateConfig()
const onBreakpoint : string = config.onMedia(config.BREAKPOINT)
const style = {
    ...getFontPoppins(), 
    marginLeft : 20, 
    marginRight : 10, 
    width : props.w ? props.w : 'auto',
    color : props.c ? props.c : 'white',
    fontSize : 10,
    [ onBreakpoint ] : { 
    //fontSize : 16,
    }
}

return <X><p {...css(style)}>{props.children}</p></X>
}







export const TitleRsp = ( props : {
children ?: any
c ?: string
}) => {
const config : GlobalConfig = validateConfig()
const onBreakpoint : string = config.onMedia(config.BREAKPOINT)
const style = {
   ...getFontBebasNeue(), 
   color : props.c ? props.c : 'white',
   fontSize : 80,
   [ onBreakpoint ] : { 
     //fontSize : 80,
   }
}

return <p {...css(style)}><X>{props.children}</X></p>

}


export const TitleRspAlt = ( props : {
children ?: any
c ?: string
}) => {
const config : GlobalConfig = validateConfig()
const onBreakpoint : string = config.onMedia(config.BREAKPOINT)
const style = {
   ...getFontLato(), 
   color : props.c ? props.c : 'white',
   textTransform : 'uppercase',
   fontSize : 50,
   textAlign : 'center',
   fontWeight : 900,
   [ onBreakpoint ] : { 
     //fontSize : 80,
   }
}

return <p {...css(style)}><X>{props.children}</X></p>

}

export const SubtitleRsp = ( props : {
        children ?: any
        c ?: string
        style?: Record<string,any>
    }) => {

    const config : GlobalConfig = validateConfig()
    const onBreakpoint : string = config.onMedia(config.BREAKPOINT)
    const style = {
        ...getFontPoppins(), 
        fontWeight : 600,
        textAlign : 'center',
        color : props.c ? props.c : 'white',
        fontSize : 26,
        [ onBreakpoint ] : { 
        //fontSize : 16,
        }
    }

    return <p {...css(style)}><X>{props.children}</X></p>
}

export const SubtitleIIRsp = ( props : {
    children ?: any
    c ?: string
    style?: Record<string,any>
    left ?: boolean
    }) => {
    const config : GlobalConfig = validateConfig()
    const onBreakpoint : string = config.onMedia(config.BREAKPOINT)
    const style = {
        ...getFontPoppins(), 
        fontWeight : 600,
        color : props.c ? props.c : 'white',
        fontSize : 20,
        [ onBreakpoint ] : { 
        //fontSize : 16,
        },
        ...props.style
    }


    return <p {...css(style)}>
        {props.left 
            ?   <X>
                    <p style={{ textAlign : 'left', width : '100%'}}>{props.children}</p>
                </X> 
            :   <X>{props.children}</X> 
        }
    </p>
}



export const AppStoreLinks = () => {
    return <>
        <div style={{ marginTop : 0, marginBottom : 50  }}>
            <img style={{ cursor : 'pointer'}} 
                //src={GoogleBadge} 
                width={150} 
                height={50}
                onClick={()=>{ 
                    window.location.href = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.locustlabs.ecard"
            }} />
            <img 
                    style={{ marginBottom : 2, cursor : 'pointer' }} 
                    //src={AppleBadge} 
                    width={150} 
                    height={45}
                    onClick={()=>{
                          window.location.href = "https://apps.apple.com/us/app/ecard-cobra/id1627863519?itsct=apps_box_link&itscg=30200"
            }}/>
        </div>
    
    </>
}


export const LoadingIndicator = () => {
    return <>
        <div style={{ height : 200 }}>
            <Y>
                <CircularProgress style={{ color : FACEBOOK_BLACK }}/>
            </Y>
        </div>
    
    </>
}

export const SubtitleIIIRsp = ( props : {
    children ?: any
    c ?: string
    style?: Record<string,any>
    }) => {
    const config : GlobalConfig = validateConfig()
    const onBreakpoint : string = config.onMedia(config.BREAKPOINT)
    const style = {
        ...getFontPoppins(), 
        color : props.c ? props.c : 'white',
        fontSize : 14,
        ...props.style,
    }

    return <p {...css(style)}><X>{props.children}</X></p>
}

export const AuthLogo = ( props : { size ?: number, color ?: string }) => {
    const size : number = props.size ? props.size : 100
    const color : string = props.color ? props.color : FACEBOOK_BLUE
    return <X style={{ height : 200 }}><div style={{ width : size, height : size }}>
        
        </div></X>
}
